import { mdiContentSave, mdiPrinter } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  dowloadXlsxFile,
  encryptData,
  getBidOpenDateTime,
  isBidder,
  isNoticeCategoryStandingList,
  isPublisher,
  printTable,
} from "../../utils/services";
import { getViewTender } from "../Api/GetApi";
import { postOpeningDate, sendOTP } from "../Api/PostApi";
import { extendSubmitDateOrOpenBid } from "../Api/PutApi";
import { BidDetails, DetailBid } from "../BidDetails/BidDetails";
import { BiddersTable } from "../BidderForm/BidderForm";
import { useAuth } from "../Context/AuthContext";
import { CustomLoader } from "../Loader/Loader";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { NotFound } from "../NotFound/NotFound";
import { ConfirmMessage } from "../PopUp/ConfirmationPopUp";
import { OTPPopUp } from "../PopUp/OTPPopUp";
import { catchError } from "../utils/catchError";
import "./BidsViewPage.scss";
import { ExtendSubmitDate } from "./ExtendSubmitDate";
import { BidCategory, BidViewBtnClick } from "../../utils/EnumConstants";
import { NoteComponent } from "../NoteComponent/NoteComponent";

export interface Bidder {
  bid_id: number;
  bidder_id: number;
  bidder_name: string;
  grand_total: number;
  location: string;
  organization_category: string;
  organization_name: string;
  status: string;
}
export interface BidderList {
  bider_lists: Array<Bidder>;
}

type SortOptions = "VALUE_ASC" | "VALUE_DSC";

const sortingOptions = [
  {
    label: "Grand Total Low to High ",
    value: "VALUE_ASC",
  },
  {
    label: "Grand Total High to Low",
    value: "VALUE_DSC",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "600px",
  },
};

export const BidsViewPage = () => {
  const { role, userData } = useAuth();
  const [bidDetail, setBidDetail] = useState<DetailBid>();
  const [bidders, setBidders] = useState<Bidder[]>([]);
  const [bidderCount, setBidderCount] = useState(0);
  const [sort, setSort] = useState("VALUE_ASC");
  const [openingDate, setOpeningDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loader, setLoader] = useState(false);
  const id = useParams();
  const [loading, setLoading] = useState(true);
  const [isOpenBids, setIsOpenBids] = useState(false);
  const [isExtendSubmitDate, setIsExtendSubmitDate] = useState(false);

  const currentDate = new Date();
  const minFinancialOpeningDate = currentDate.toISOString().split("T")[0];

  const minPubCheckDate = new Date();
  minPubCheckDate.setDate(minPubCheckDate.getDate() + 1);
  const minPublishingDate = minPubCheckDate.toISOString().split("T")[0];

  const [newSubmitDate, setNewSubmitDate] = useState(minPublishingDate);
  const [extendScheduleLoading, setExtendScheduleLoading] = useState(false);
  const [openBidLoading, setOpenBidLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const toggleOpenBidsModal = () => {
    setIsOpenBids(!isOpenBids);
    setIsExtendSubmitDate(false);
  };

  const toggleExtendSubmitDateModal = () => {
    setIsOpenBids(false);
    setIsExtendSubmitDate(!isExtendSubmitDate);
  };

  const FetchViewTender = (id: string | undefined) => {
    getViewTender(id)
      .then((res) => {
        if (res.status === 200) {
          const reqData = res.data.data;
          setBidDetail({
            tender_number: reqData.tender_number,
            pubilshing_date: reqData.publishing_date,
            submission_date: reqData.submission_date,
            title: reqData.title,
            procurement_type: reqData.procurement_type,
            project_category: reqData.project_category,
            notice_category: reqData.notice_category,
            district: reqData.district,
            public_entity_name: reqData.public_entity_name,
            isPrivate: reqData.private,
            ppmoFileUrl: reqData?.ppmo_file_url,
            publisherId: reqData?.user,
            image: reqData?.image,
            boq: reqData?.boq,
            savebids: reqData?.savebids,
            category: reqData?.category,
            opening_date: reqData?.opening_date,
            specification_terms_format: reqData?.specification_terms_format,
            technical_pass: reqData.technical_pass,
            isVat: reqData.is_vat,
            privateBid: reqData.private,
            remainingDays: reqData.remaining_days,
            hide_bidder: reqData.hide_bidder,
            notes: reqData.notes,
            terms: reqData.terms,
            open_bid: reqData.open_bid,
            additional_attributes: reqData.additional_attributes,
            isMain: reqData.is_main,
            user_info: reqData?.user_info,
          });
          setBidders(reqData.bider_lists);
          setBidderCount(reqData.bidder_count);
        }
      })
      .catch()
      .finally(() => setLoading(false));
  };

  const sortedTasks = useMemo(() => {
    const stats = bidders;
    if (sort) {
      const [key, value] = sort.split("_");
      if (key === "VALUE") {
        //sort each taskArray
        stats.sort((a, b) => {
          const aGrand = a.grand_total;
          const bGrand = b.grand_total;
          if (value === "ASC") {
            return aGrand - bGrand;
          } else {
            return bGrand - aGrand;
          }
        });
      }
    }
    return stats;
  }, [sort, bidders]);

  useEffect(() => {
    FetchViewTender(id.id);
  }, [id.id, sort]);

  const submitDate = () => {
    setLoader(true);
    const data = {
      tender_notice_id: id?.id,
      opening_date: openingDate,
    };
    postOpeningDate(data)
      .then((res) => {
        toast.success(res.data.status.status_message);
      })
      .catch((err) => {
        catchError(
          err.response?.status,
          err.response?.data.status.status_message
        );
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const popUpContents: ConfirmMessage = {
    question1: "Open Bids?",
    question2: "Are you sure you want to Open bid?",
    warn: "If you open your bid now, you can't extend submission date.",
    cancelBtn: "Cancel",
    aprroveBtn: "Yes, Open",
  };

  const handleSendOTP = (clickedBtn: BidViewBtnClick) => {
    
    sendOTP({ tender_notice_id: id.id as string })
      .then((res) => {
        toast.success(res.data.status.status_message);
        if (clickedBtn === BidViewBtnClick.OPEN_BIDS) {
          if (!isOpenBids) {
            toggleOpenBidsModal();
          }
        } else {
          if (!isExtendSubmitDate) {
            toggleExtendSubmitDateModal();
          }
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const handleOpenBids = () => {
    setOpenBidLoading(true);
    extendSubmitDateOrOpenBid(id.id as string, {
      open_bid: true,
      otp: btoa(encryptData(otp)),
    })
      .then((res) => {
        FetchViewTender(id.id);
        toast.success(res.data.status.status_message);
        toggleOpenBidsModal();
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setOpenBidLoading(false));
  };

  const handleExtend = (newDate: string) => {
    setExtendScheduleLoading(true);
    extendSubmitDateOrOpenBid(id.id as string, {
      resubmission_date: newDate,
      otp: btoa(encryptData(otp)),
    })
      .then((res) => {
        FetchViewTender(id.id);
        toast.success(res.data.status.status_message);
        toggleExtendSubmitDateModal();
      })
      .catch((err) => {
        catchError(
          err?.response?.status,
          err?.response?.data?.status?.status_message
        );
      })
      .finally(() => setExtendScheduleLoading(false));
  };

  const publisher = isPublisher(role);

  const isNoticeDoubleEnvelop =
    publisher && bidDetail?.category === BidCategory.DOUBLE_ENVELOPE;

  const showOpeningDate =
    isNoticeDoubleEnvelop &&
    bidders?.length > 0 &&
    !isNoticeCategoryStandingList(bidDetail?.notice_category ?? "");

  const showEnlistmentTechnicalComplete =
    isNoticeCategoryStandingList(bidDetail?.notice_category ?? "") &&
    bidDetail?.isMain &&
    bidders?.length > 0;

  const showTableOptions =
    isNoticeCategoryStandingList(bidDetail?.notice_category ?? "") &&
    bidDetail?.isMain
      ? userData?.id === bidDetail?.publisherId
      : publisher || !bidDetail?.hide_bidder;

  const downloadColumns = [
    {
      Header: "Organization Name",
      accessor: "organization_name",
    },
    {
      Header: "Contact Number",
      accessor: "contact_no",
    },
    {
      Header: "Bid Fee Deposited Date",
      accessor: "bid_fee_voucher_date",
    },
  ];

  return (
    <>
      <Modal
        isOpen={isExtendSubmitDate}
        onRequestClose={toggleExtendSubmitDateModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <ExtendSubmitDate
          extendLoading={extendScheduleLoading}
          newSubmitDate={newSubmitDate}
          setNewSubmitDate={setNewSubmitDate}
          onHandleExtend={() => {
            handleExtend(newSubmitDate);
          }}
          minPublishingDate={minPublishingDate}
          toggleExtendSubmitDateModal={toggleExtendSubmitDateModal}
          setOtp={setOtp}
          otp={otp}
          onResendOTP={() => {
            handleSendOTP(BidViewBtnClick.EXTEND_SUBMISSION_DATE);
          }}
        />
      </Modal>

      <Modal
        isOpen={isOpenBids}
        onRequestClose={toggleOpenBidsModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <OTPPopUp
          popUpContents={popUpContents}
          toggleModal={toggleOpenBidsModal}
          setOtp={setOtp}
          otp={otp}
          onAprroveButton={() => {
            handleOpenBids();
          }}
          onResendOTP={() => {
            handleSendOTP(BidViewBtnClick.OPEN_BIDS);
          }}
          activeButtonLoading={openBidLoading}
        />
      </Modal>

      {loading ? (
        <div className="spinner">
          <MyBidLoader />
        </div>
      ) : (
        <div className="detail-wrapper">
          {bidDetail ? (
            <>
              <BidDetails bidDetail={bidDetail} detailView={true} />
              <div className="mt-4">
                {bidDetail.open_bid &&
                  isBidder(role) &&
                  !bidDetail.opening_date && (
                    <NoteComponent note="You will be notified regarding the status after the evaluation is completed by the Publisher. Please note that the evaluation period may take time." />
                  )}
              </div>
              <div className="bidder-list">Bidder List</div>
              {bidDetail.open_bid ? (
                <>
                  <div className="container">
                    <div className="d-space-between align-items-end">
                      <div>
                        {showOpeningDate &&
                          userData?.id === bidDetail.publisherId && (
                            <div className="my-3">
                              <label className="mg-0">
                                Opening Date of Financial Document
                              </label>
                              <div className="d-flex">
                                <input
                                  type="date"
                                  className="form-control opening-date"
                                  onChange={(e: any) => {
                                    setOpeningDate(e.target.value);
                                  }}
                                  min={minFinancialOpeningDate}
                                />

                                <button
                                  className="primary-btn mg-btn-left"
                                  onClick={() => {
                                    submitDate();
                                  }}
                                >
                                  {loader ? (
                                    <>
                                      <CustomLoader
                                        type="TailSpin"
                                        color="#ff0026"
                                        height={22}
                                        width={50}
                                      />
                                    </>
                                  ) : (
                                    <span>
                                      {" "}
                                      <Icon
                                        className="mdi-print"
                                        path={mdiContentSave}
                                      />
                                      Submit
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          )}

                        {showEnlistmentTechnicalComplete &&
                          userData?.id === bidDetail.publisherId && (
                            <button
                              className="primary-btn my-3"
                              onClick={() => {
                                submitDate();
                              }}
                            >
                              {loader ? (
                                <>
                                  <CustomLoader
                                    type="TailSpin"
                                    color="#ff0026"
                                    height={22}
                                    width={50}
                                  />
                                </>
                              ) : (
                                <span>
                                  {" "}
                                  <Icon
                                    className="mdi-print"
                                    path={mdiContentSave}
                                  />
                                  Complete Evaluation
                                </span>
                              )}
                            </button>
                          )}
                      </div>

                      {showTableOptions && (
                        <div
                          style={{
                            // float: "right",
                            justifyContent: "end",
                            margin: "15px 0",
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <div>
                            <label className="select-label">Sort: </label>

                            <select
                              className="select-box"
                              onChange={(e) => {
                                setSort(e.target.value as SortOptions);
                              }}
                            >
                              {sortingOptions.map((item) => {
                                return (
                                  <option key={item.label} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <button
                            className="primary-btn"
                            onClick={() => {
                              printTable("print-table", bidDetail);
                            }}
                          >
                            <Icon className="mdi-print" path={mdiPrinter} />
                            Print
                          </button>
                          {userData?.id === bidDetail.publisherId && (
                            <div className="excel-download">
                              <button
                                className="btn btn-success download-btn"
                                onClick={() => {
                                  dowloadXlsxFile(
                                    downloadColumns,
                                    sortedTasks,
                                    null
                                  );
                                }}
                              >
                                <i className="bi bi-file-earmark-arrow-down-fill"></i>
                                Export
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {showTableOptions ? (
                    <BiddersTable bidders={sortedTasks} bidDetail={bidDetail} />
                  ) : (
                    <div className="expire-bid">
                      <div className="imageCenter">
                        <img
                          className="expiry-img"
                          src={require("../../images/bidders-lock.png")}
                          alt="img"
                        />
                      </div>
                      <div>
                        <div>
                          <b>
                            Note: The publisher has chosen to keep the
                            participant's details confidential.
                          </b>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="expire-bid">
                  {publisher && userData?.id === bidDetail.publisherId && (
                    <h6>
                      <strong>No.of Bids Received: </strong>
                      {bidderCount || 0}
                    </h6>
                  )}
                  <div className="imageCenter">
                    <img
                      className="expiry-img"
                      src={require("../../images/bidders-lock.png")}
                    />
                  </div>
                  <div>
                    {getBidOpenDateTime(bidDetail.submission_date).getTime() >=
                      currentDate.getTime() || !publisher ? (
                      <div>
                        The Bid will be available to be opened after
                        <span className="" style={{ color: "#ff0026" }}>
                          {" " +
                            getBidOpenDateTime(
                              bidDetail.submission_date
                            ).toLocaleString()}
                        </span>
                        . The Publisher is responsible for opening the bids.
                      </div>
                    ) : (
                      <>
                        {userData?.id === bidDetail.publisherId && (
                          <div>
                            <button
                              onClick={() => {
                                handleSendOTP(BidViewBtnClick.EXTEND_SUBMISSION_DATE);
                              }}
                              className="search-btn pd-btn"
                            >
                              Extend Submission Date
                            </button>
                            <button
                              onClick={() => {
                                handleSendOTP(BidViewBtnClick.OPEN_BIDS);
                              }}
                              className="open-bids"
                            >
                              Open Bids
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <NotFound message="Something Went Wrong" />
            </div>
          )}
        </div>
      )}
    </>
  );
};
