import React, { useState } from "react";
import "./NavBar.scss";
import { Link, useLocation } from "react-router-dom";
import { TopHeader } from "../TopHeader/TopHeader";
import { useAuth } from "../Context/AuthContext";
import { NavLink } from "react-router-dom";
import { isBidder, isPublisher } from "../../utils/services";

export const NavBar = () => {
  window.scrollTo(0, 0);

  const { isAuthenticate, role } = useAuth();
  const { pathname } = useLocation();
  if (
    pathname.includes("/payment_verification") ||
    pathname.includes("/user")
  ) {
    return null;
  }

  return (
    <div className="navbar-section">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link to="/" className="navbar-brand">
            <img src={require("../../images/logo.svg").default} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div id="navbarSupportedContent" className="hide">
            <div className="collapse navbar-collapse">
              <div className="navActive ">
                <NavLink
                  to="/"
                  className={(navData) =>
                    navData.isActive ? "active" : "not-active"
                  }
                >
                  Home<span className="sr-only">(current)</span>
                </NavLink>

                {isPublisher(role) && (
                  <NavLink
                    to="/bids"
                    className={(navData) =>
                      navData.isActive ? "active" : "not-active"
                    }
                  >
                    All Bids<span className="sr-only"></span>
                  </NavLink>
                )}

                {/* <NavLink
                  className={(navData) =>
                    navData.isActive ? "active" : "not-active"
                  }
                  to="/results"
                >
                  Results
                </NavLink> */}
                {isAuthenticate && isBidder(role) && (
                  <>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "active" : "not-active"
                      }
                      to={"/my-ebids"}
                    >
                      My E-Bids
                    </NavLink>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "active" : "not-active"
                      }
                      to={"/saved-bids"}
                    >
                      Saved Bids
                    </NavLink>
                  </>
                )}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active" : "not-active"
                  }
                  to="/community"
                >
                  Community
                </NavLink>
                <a
                  target={"_blank"}
                  className={"not-active"}
                  href="https://bazarpapa.com/"
                >
                  Bazar
                </a>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active" : "not-active"
                  }
                  to={"/pricing-plan"}
                >
                  Pricing
                </NavLink>
              </div>
            </div>
            <div>
              <TopHeader />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
