import {
  mdiAlertCircleOutline,
  mdiArrowRight,
  mdiArrowURightTop,
  mdiCheckCircleOutline,
  mdiClose,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiPencilOutline,
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { useContext, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBid, deleteTenderNotice } from "../../Components/Api/DeleteApi";
import { tenderBid } from "../../Components/Api/PostApi";
import { UpdateMyBids } from "../../Components/Api/PutApi";
import { BidPaymentPopUp } from "../../Components/BidPaymentPopUp/BidPaymentPopUp";
import { useAuth } from "../../Components/Context/AuthContext";
import { ModalContext } from "../../Components/Context/ModalContext/ModalContext";
import { PaymentPopUp } from "../../Components/PaymentPopUp/PaymentPopUp";
import {
  ConfirmationPopUp,
  ConfirmMessage,
} from "../../Components/PopUp/ConfirmationPopUp";
import { ProcurementType } from "../../Components/TenderCard/TenderCard";
import { ResponseTenderCard } from "../../Components/TenderCard/TenderCardGroup";
import { catchError } from "../../Components/utils/catchError";
import {
  BidStatus,
  EOIStatus,
  PaymentFeeType,
} from "../../utils/EnumConstants";
import {
  bidderButtonShow,
  CheckTechnicalPassStatus,
  getDateDifferenceInDays,
  isBidder,
  isNoticeCategoryEOI,
  isNoticeCategoryStandingList,
  isPublisher,
  modalStatesSetAllFalseExceptGiven,
  publisherButtonShow,
} from "../../utils/services";
import "./MyBids.scss";

export interface MyBidsProps {
  type: string;
  reFetchMyBids?: () => void;
  tenderNoticeData: ResponseTenderCard;
  isPublisherProfile?: boolean;
}

export const MyBids: React.FC<MyBidsProps> = ({
  reFetchMyBids,
  type,
  tenderNoticeData,
  isPublisherProfile = false,
}) => {
  const { role, isAuthenticate, eligible } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);
  const {
    id: tender_id = 1,
    rejection_reason: rejectionReason = "", // Assign to new key
    award_result: awardResult = [],
    district,
    procurement_type,
    notice_category,
    technical_pass = null,
    amendment,
    tender_number,
    title,
    publishing_date,
    extra_info,
    submission_date,
    public_entity_name,
    remaining_days,
    system_bid_fee,
    publisher_bid_fee,
    opening_date,
    saved,
    published,
    user,
    specification_terms_format,
    is_main,
  } = tenderNoticeData;

  const TenderDeletePopUpMessage: ConfirmMessage = {
    question1: "Delete Tender",
    question2: "Are you sure you want to delete tender?",
    warn: "If you delete notice, your tender notice won't be seen by bidders.",
    cancelBtn: "No",
    aprroveBtn: "Yes, Delete",
  };
  const BidDeletePopUpMessage: ConfirmMessage = {
    question1: "Delete Bid",
    question2: "Are you sure you want to delete ?",
    warn: "If you delete bid, your bid won't be seen by publishers.",
    cancelBtn: "No",
    aprroveBtn: "Yes, Delete",
  };

  const WithdrawPopUpMessages: ConfirmMessage = {
    question1: "Withdraw Bid",
    question2: "Are you sure you want to withdraw ?",
    warn: "If you withdraw your bid now, you won’t get a refund of your security deposit and bid fee.",
    cancelBtn: "Cancel Withdraw",
    aprroveBtn: "Yes, Withdraw",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [accept, setAceept] = useState(false);
  const [tenderModal, setTenderModal] = useState(false);
  const [modalStates, setModalStates] = useState({
    isTenderDelete: false,
    bidModal: false,
    tenderModal: false,
    isBidDelete: false,
  });

  const UpdateModalState = (
    obj: typeof modalStates,
    property: keyof typeof modalStates
  ) => {
    const updatedObj = modalStatesSetAllFalseExceptGiven<typeof modalStates>(
      obj,
      property
    );
    //@ts-ignore
    setModalStates(updatedObj);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "510px",
    },
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const moveToWithdrawn = (
    id: string | number,
    requestType: string | number
  ) => {
    UpdateMyBids(id, requestType).then((res) => {
      toast.success(res.data.status.status_message);
      reFetchMyBids?.();
      toggleModal();
    });
  };

  const DeleteTender = (id: number | string) => {
    deleteTenderNotice(id)
      .then((res) => {
        toast.success(res.data.status.status_message);
        reFetchMyBids?.();
        toggleModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error deleteing bid");
      });
  };

  const DeleteBid = (id: number | string) => {
    deleteBid(id)
      .then((res) => {
        toast.success(res.data.status.status_message);
        reFetchMyBids?.();
        toggleModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error deleteing bid");
      });
  };

  const EOIComponent = (Eoistatus: EOIStatus) => {
    let component = <></>;
    switch (Eoistatus) {
      case EOIStatus.Submitted:
        component = (
          <span className="badge-child badge badge-pill badge-info">
            EOI {EOIStatus.Submitted}
          </span>
        );
        break;
      case EOIStatus.Pass:
        component = (
          <span className="badge-child badge badge-pill badge-success">
            EOI {EOIStatus.Pass}
          </span>
        );
        break;
      case EOIStatus.Fail:
        component = (
          <span className="badge-child badge badge-pill badge-danger">
            EOI {EOIStatus.Fail}
          </span>
        );
        break;
      default:
        return component;
    }
    return component;
  };

  const {
    showPay,
    showEdit,
    showDelete,
    showView,
    showWithdrawn,
    showResubmit,
    showViewDocument,
    showApplyBid,
  } = bidderButtonShow(tenderNoticeData, type, isPublisherProfile);

  const {
    showPublisherPay,
    showPublisherEdit,
    showPublisherDelete,
    showPublisherView,
    showRequestQuotation,
  } = publisherButtonShow(tenderNoticeData, type);

  const calculateRemainingDays = (
    opening_date: string | undefined,
    remaining_days: number
  ) => {
    var days = remaining_days;
    if (opening_date) {
      days = getDateDifferenceInDays(
        new Date().toISOString().slice(0, 16),
        opening_date
      );
    }
    return days <= 0 ? (
      <span className="text-danger">Expired</span>
    ) : (
      <span>{days + " Days Remaining"}</span>
    );
  };

  const FirstBidCheck = (tender_id: string | number | undefined) => {
    const data = {
      tender_notice_id: tender_id,
    };
    tenderBid(data)
      .then((res) => {
        if (res.status === 202) {
          toast.error(res.data.status.status_message);
        }
        if (res.status === 200) {
          window.open(`/tender-bid/${tender_id}`, "_blank");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return TogglePopUpFlags("login");
        }
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const handleOnClickBid = (id: number) => {
    if (isAuthenticate && isBidder(role)) {
      if (eligible) {
        FirstBidCheck(id);
      } else {
        TogglePopUpFlags("editCompanyDetail");
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  return (
    <div className="mybids-wrapper">
      <div className="card bid-card">
        <div className="card-section">
          {awardResult && awardResult.length > 0 && (
            <>
              <div className="blue-highlight">
                <Icon
                  className="mdi-alert blue-icon"
                  path={mdiCheckCircleOutline}
                ></Icon>
                <span>
                  Awarded to:{" "}
                  {awardResult?.map((award: any, index: number) => (
                    <span key={award.id}>
                      {award.awarded
                        ? "You"
                        : award?.company +
                          (index !== awardResult.length - 1 ? " | " : "")}
                    </span>
                  ))}
                </span>
              </div>
            </>
          )}
          {isBidder(role) && rejectionReason && (
            <div className="red-highlight">
              <div>
                <Icon className="mdi-alert" path={mdiAlertCircleOutline}></Icon>
              </div>
              Disqualified: {rejectionReason}
            </div>
          )}

          <div className="tid-content">
            <div className="d-flex">
              <div className="tender-header">
                <div className="tag-info">
                  <img
                    className="map-icon"
                    src={require("../../images/location.svg").default}
                    alt="images"
                  />
                  <p className="tender-contents">
                    <span className="tender-bold">{district}</span>
                  </p>
                  <div className="border-line"></div>

                  <p className="tender-contents">
                    <span className="tender-bold">
                      {procurement_type?.map(
                        (item: ProcurementType, index: number) => {
                          if (index !== procurement_type.length - 1) {
                            return item.label + ",";
                          }
                          return item.label;
                        }
                      )}
                    </span>
                  </p>
                </div>
                <div className="border-line"></div>
                <div className="tag-info">
                  <p className=" tender-contents">
                    <span className="tender-bold">{notice_category}</span>
                  </p>
                </div>
              </div>
              <div className="badge-parent">
                {isBidder(role) &&
                  type === BidStatus.EOI_STATUS &&
                  isNoticeCategoryEOI(notice_category as string) && (
                    <>
                      {EOIComponent(CheckTechnicalPassStatus(technical_pass))}
                    </>
                  )}
                {isBidder(role) && (
                  <>
                    {extra_info?.e_payment === "Submitted" && (
                      <span className="badge-child badge badge-pill badge-success">
                        Paid
                      </span>
                    )}
                  </>
                )}
                {isPublisher(role) && (
                  <div className="d-flex">
                    <p>
                      {amendment && (
                        <span className="ml-2 badge-child badge badge-pill badge-primary ">
                          Amendment
                        </span>
                      )}
                    </p>
                    {isNoticeCategoryStandingList(notice_category) &&
                      !saved &&
                      type === BidStatus.ENLISTMENT &&
                      published && (
                        <span className="badge-child badge badge-pill badge-success">
                          Published
                        </span>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="tender-flex ">
              <div>
                <p className="tid-no">
                  TID :<span className="tender-bold">{tender_number}</span>
                </p>
              </div>
            </div>
          </div>

          <div>
            <p className="tender-title">
              {title}{" "}
              {isNoticeCategoryStandingList(notice_category) && !is_main && (
                <span className="tag">For Listed Vendor</span>
              )}
            </p>
          </div>
          <div>
            <p className="sub-title">{public_entity_name}</p>
          </div>
          <div className="tender-border"></div>

          <div className="card-wrapper">
            <div className="d-flex mg-b-10">
              <div className="wraped">
                <div className="bid-before">
                  <div className="tag-info ">
                    <img src={require("../../images/bid.png")} alt="images" />
                    <p className="tender-contents ">
                      Published Date
                      <span className="tender-bold">{publishing_date}</span>
                    </p>
                  </div>

                  <div className="border-line"> </div>

                  <div className="bid-before">
                    <div className="tag-info">
                      <p className="tender-contents">
                        Apply Before:
                        <span className="tender-bold">
                          {opening_date
                            ? opening_date.slice(0, 10)
                            : submission_date.slice(0, 10)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                {isBidder(role) && extra_info?.joint_venture_name && (
                  <div className="d-flex align-center">
                    <span className="border-line"></span>

                    <div className="bid-before">
                      <div className="tag-info">
                        <img
                          src={require("../../images/joint-venture.png")}
                          alt="images"
                        />
                        <p className="tender-contents">
                          Joint Venture Name:
                          <span className="tender-bold">
                            {extra_info?.joint_venture_name}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="wraped">
                <div className="days-remain">
                  <p className="days-count">
                    {calculateRemainingDays(opening_date, remaining_days)}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex ">
              {isBidder(role) && (
                <>
                  <div className="btn-bid">
                    {isNoticeCategoryStandingList(notice_category) &&
                    !is_main ? (
                      <></>
                    ) : (
                      showApplyBid &&
                      Number(remaining_days) > 0 && (
                        <div className="btn-combination mr-2">
                          <div className="mg-btn-right">
                            <button
                              className="transparent-btn"
                              onClick={() => {
                                handleOnClickBid(tender_id);
                              }}
                            >
                              <img
                                className="btn-img-icon-size"
                                src={require("../../images/bidblue.png")}
                                alt="images"
                              />
                              Apply Bid
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    {showPay && (
                      <>
                        <button
                          className="primary-btn"
                          onClick={() => {
                            UpdateModalState(modalStates, "bidModal");
                            toggleModal();
                          }}
                        >
                          <Icon
                            className="pencil-square"
                            path={mdiCreditCardOutline}
                          />
                          Pay
                        </button>

                        {modalStates.bidModal && (
                          <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            contentLabel="My dialog"
                            style={customStyles}
                            ariaHideApp={false}
                          >
                            <BidPaymentPopUp
                              toggleModal={toggleModal}
                              bidFee={system_bid_fee || publisher_bid_fee}
                              tender_notice_id={tender_id}
                              refetchMyBids={reFetchMyBids}
                            />
                          </Modal>
                        )}
                      </>
                    )}
                    {showEdit && (
                      <div className="mg-btn-left">
                        <Link
                          to={`/my-ebids/edit/${tender_id}`}
                          target="_blank"
                        >
                          <button className="transparent-btn">
                            {isNoticeCategoryEOI(notice_category as string) &&
                            CheckTechnicalPassStatus(technical_pass) ===
                              EOIStatus.Pass ? (
                              <>
                                <Icon
                                  className="pencil-square"
                                  path={mdiArrowRight}
                                />
                                Proceed
                              </>
                            ) : (
                              <>
                                <Icon
                                  className="pencil-square"
                                  path={mdiPencilOutline}
                                />
                                Edit
                              </>
                            )}
                          </button>
                        </Link>
                      </div>
                    )}
                    {showDelete && (
                      <>
                        <button
                          className="delete-btn"
                          onClick={() => {
                            toggleModal();
                            UpdateModalState(modalStates, "isBidDelete");
                          }}
                        >
                          <Icon
                            className="pencil-square mdi-eye"
                            path={mdiClose}
                          />
                          Delete
                        </button>

                        {modalStates.isBidDelete && (
                          <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            contentLabel="My dialog"
                            style={customStyles}
                            ariaHideApp={false}
                          >
                            <ConfirmationPopUp
                              popUpContents={BidDeletePopUpMessage}
                              toggleModal={() => toggleModal()}
                              onAprroveButton={() => DeleteBid(tender_id)}
                            />
                          </Modal>
                        )}
                      </>
                    )}
                  </div>
                  <div className="d-flex align-center mg-btn-left">
                    {showViewDocument && (
                      <Link
                        to={`/bids/view/${tender_id}/${user}`}
                        target="_blank"
                        className="mr-3"
                      >
                        <button className="transparent-btn">
                          <Icon
                            className="pencil-square"
                            path={mdiFileDocumentOutline}
                          />
                          View Document
                        </button>
                      </Link>
                    )}
                    {showView && (
                      <Link
                        to={
                          specification_terms_format
                            ? `/bids/view/${tender_id}`
                            : `/e-notice/view/${tender_id}`
                        }
                        target="_blank"
                      >
                        <button className="primary-btn">
                          <Icon
                            className="pencil-square"
                            path={mdiEyeOutline}
                          />
                          View
                        </button>
                      </Link>
                    )}

                    {showWithdrawn && (
                      <>
                        <button
                          className="delete-btn"
                          onClick={() => {
                            toggleModal();
                            setAceept(!accept);
                          }}
                        >
                          <Icon
                            className="pencil-square mdi-eye"
                            path={mdiClose}
                          />
                          Withdraw
                        </button>
                        <Modal
                          isOpen={isOpen}
                          onRequestClose={toggleModal}
                          contentLabel="My dialog"
                          style={customStyles}
                        >
                          <ConfirmationPopUp
                            popUpContents={WithdrawPopUpMessages}
                            toggleModal={() => toggleModal()}
                            onAprroveButton={() =>
                              moveToWithdrawn(tender_id, 0)
                            }
                          />
                        </Modal>
                      </>
                    )}
                  </div>
                  {showResubmit && (
                    <div className="btn-bid">
                      <button
                        className="delete-btn"
                        onClick={() => {
                          moveToWithdrawn(tender_id, 1);
                        }}
                      >
                        <Icon
                          className="resubmit-icon"
                          path={mdiArrowURightTop}
                        />
                        Resubmit
                      </button>
                    </div>
                  )}
                </>
              )}

              {/* in case of publisher */}
              {isPublisher(role) && (
                <div className="btn-bid">
                  {showPublisherPay && (
                    <button
                      className="search-btn btn-padd btn-radius btn-mg"
                      onClick={() => {
                        setTenderModal(true);
                        toggleModal();
                      }}
                    >
                      <Icon
                        className="pencil-square"
                        path={mdiCreditCardOutline}
                      />
                      Pay
                    </button>
                  )}
                  {showPublisherEdit && (
                    <div className="mg-btn-left">
                      <Link
                        to={
                          !specification_terms_format
                            ? `/publish-e-notice/edit/${tender_id}`
                            : isNoticeCategoryStandingList(
                                tenderNoticeData.notice_category
                              )
                            ? `/my-tenders/edit/${tender_id}?type=enlistment`
                            : `/my-tenders/edit/${tender_id}`
                        }
                        target="_blank"
                      >
                        <button type="button" className="transparent-btn">
                          <Icon
                            className="pencil-square"
                            path={mdiPencilOutline}
                          />
                          Edit
                        </button>
                      </Link>
                    </div>
                  )}
                  {showRequestQuotation &&
                    isNoticeCategoryStandingList(type) && (
                      <>
                        <div className="mg-btn-left">
                          <Link
                            to={`/my-tenders/${tender_id}/enlistment/quotation`}
                            target="_blank"
                          >
                            <button type="button" className="transparent-btn">
                              <Icon
                                className="pencil-square"
                                path={mdiFileDocumentOutline}
                              />
                              Ask for Quotation
                            </button>
                          </Link>
                        </div>
                      </>
                    )}
                  {showPublisherView && (
                    <>
                      <Link
                        to={
                          type === BidStatus.E_NOTICE
                            ? `/e-notice/view/${tender_id}`
                            : `/bids/view/${tender_id}`
                        }
                        target="_blank"
                      >
                        <div className="mg-btn-left">
                          <button className="primary-btn">
                            <Icon
                              className="pencil-square"
                              path={mdiEyeOutline}
                            />
                            View
                          </button>
                        </div>
                      </Link>
                    </>
                  )}
                  {showPublisherDelete && (
                    <>
                      <button
                        className="delete-btn"
                        onClick={() => {
                          UpdateModalState(modalStates, "isTenderDelete");
                          toggleModal();
                        }}
                      >
                        <Icon className="pencil-square" path={mdiClose} />
                        Delete
                      </button>
                      {modalStates.isTenderDelete && (
                        <Modal
                          isOpen={isOpen}
                          onRequestClose={toggleModal}
                          contentLabel="My dialog"
                          style={customStyles}
                          ariaHideApp={false}
                        >
                          <ConfirmationPopUp
                            popUpContents={TenderDeletePopUpMessage}
                            toggleModal={() => toggleModal()}
                            onAprroveButton={() => DeleteTender(tender_id)}
                          />
                        </Modal>
                      )}
                    </>
                  )}
                  {tenderModal && (
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={toggleModal}
                      contentLabel="My dialog"
                      style={customStyles}
                      ariaHideApp={false}
                    >
                      <PaymentPopUp
                        toggleModal={toggleModal}
                        tender_notice_id={tender_id}
                        refetchMyBids={reFetchMyBids}
                        fee_for={PaymentFeeType.Publish}
                      />
                    </Modal>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
