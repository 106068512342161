import {
  mdiClose,
  mdiContentCopy,
  mdiEmailOutline,
  mdiLink,
  mdiLinkedin,
  mdiWhatsapp,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  EmailShareButton,
  LinkedinShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import "./ENoticeContent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faViber } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";

interface ShareIconsProps {
  shareUrl: string;
  noticeTitle?: string;
  toggleModal: () => void;
}

export const ShareIcons: React.FC<ShareIconsProps> = ({
  shareUrl,
  noticeTitle,
  toggleModal,
}) => {
  const handleCopyLink = (shareUrl: string) => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy the link: ", error);
      });
  };

  return (
    <div className="share-icon-section">
      <div className="d-flex justify-content-between custom-mb-16">
        <h4 className="block-title">Share this notice: </h4>
        <div className="close-icon" onClick={toggleModal}>
          <Icon className=" mdi-close" path={mdiClose} />
        </div>
      </div>
      <div className="share-icon">
        <div className="icon-div icon-email" title="Email">
          <EmailShareButton url={shareUrl} title={noticeTitle}>
            <Icon path={mdiEmailOutline} className="mdi-social " />
          </EmailShareButton>
        </div>
        <div className="icon-div icon-linkedin" title="Linked In">
          <LinkedinShareButton url={shareUrl}>
            <Icon path={mdiLinkedin} className="mdi-social" />
          </LinkedinShareButton>
        </div>
        <div className="icon-div icon-viber" title="Viber">
          <ViberShareButton
            url={shareUrl}
            title={noticeTitle}
            className="Demo__some-network__share-button"
          >
            <FontAwesomeIcon icon={faViber} className="mdi-social" />
          </ViberShareButton>
        </div>
        <div className="icon-div icon-whatsapp" title="Whatsapp">
          <WhatsappShareButton
            url={shareUrl}
            title={noticeTitle}
            separator=":: "
          >
            <Icon path={mdiWhatsapp} className="mdi-social" />
          </WhatsappShareButton>
        </div>
        <div
          className="icon-div icon-copy"
          onClick={() => {
            handleCopyLink(shareUrl);
          }}
          title="Copy Link"
        >
          <Icon path={mdiLink} className="mdi-social" />
        </div>
      </div>
    </div>
  );
};
