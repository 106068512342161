import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { About } from "../../Components/About/About";
import { getPublisherData } from "../../Components/Api/GetApi";
import { useAuth } from "../../Components/Context/AuthContext";
import { MyBidsPage } from "../../Components/MyBidsPage/MyBidsPage";
import NewPublisherBanner from "../../Components/NewPublisherBanner/NewPublisherBanner";
import { catchError } from "../../Components/utils/catchError";
import "./PublisherHomePage.scss";

export const PublisherHomePage = () => {
  const { id } = useParams();
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const { userData } = useAuth();
  const profileUserID = id || userData?.id;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showButton) {
        setShowButton(true);
        return;
      }
      if (window.scrollY === 0) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const FetchPublisherData = (userID: string) => {
    setLoading(true);
    getPublisherData(userID as string)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (profileUserID) {
      FetchPublisherData(profileUserID);
    }
  }, [userData]);

  return (
    <div className="publisher-home-page">
      {showButton && (
        <img
          className={`scroll-top-btn`}
          src={require("../../images/scroll-top.svg").default}
          alt="scroll-to-top"
          onClick={handleClick}
        />
      )}
      <NewPublisherBanner profileData={data} loading={loading} />
      <MyBidsPage isPublisherProfile={true} userID={profileUserID} />
      <About profileData={data} />
    </div>
  );
};
