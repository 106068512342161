import { mdiFacebook, mdiFacebookMessenger, mdiShareVariant } from "@mdi/js";
import Icon from "@mdi/react";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
} from "react-share";
import Modal from "react-modal";
import { ETenderContentShimmer } from "../ETenderContentShimmer/ETenderContentShimmer";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import "./ENoticeContent.scss";
import { useState } from "react";
import { ShareIcons } from "./ShareIcons";

export interface ENoticeContentProps {
  noticeData?: string;
  noticeLoading?: boolean;
  noticeTitle?: string;
}

const ENoticeContent: React.FC<ENoticeContentProps> = ({
  noticeData,
  noticeLoading,
  noticeTitle,
}) => {
  const shareUrl = window.location.href;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      padding: "20px",
      maxHeight: "650px",
    },
  };

  return (
    <>
      {noticeLoading ? (
        <ETenderContentShimmer />
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            style={customStyles}
            ariaHideApp={false}
          >
            <ShareIcons toggleModal={toggleModal} shareUrl={shareUrl} noticeTitle={noticeTitle} />
          </Modal>
          <div className="enotice-content">
            {noticeData ? (
              <div className="enotice-details-section">
                <div
                  className="enotice-content-section"
                  dangerouslySetInnerHTML={{
                    __html: noticeData as string,
                  }}
                />
                <div className="enotice-footer">
                  {/* <div className="share-count">
                    <p className="share-title">59</p>
                    <p className="share-content">Shares</p>
                  </div> */}
                  <div className="share-icon">
                    <div className="icon-div icon-facebook" title="Facebook">
                      <FacebookShareButton
                        url={shareUrl}
                        quote={noticeTitle}
                        className="mdi-icon fb mg-r color-gradient"
                      >
                        <Icon path={mdiFacebook} className="mdi-social" />
                      </FacebookShareButton>
                    </div>
                    <div className="icon-div icon-messenger" title="Messenger">
                      <FacebookMessengerShareButton
                        url={shareUrl}
                        appId="521270401588372"
                        className="mdi-icon fb mg-r color-gradient"
                      >
                        <Icon
                          path={mdiFacebookMessenger}
                          className="mdi-social"
                        />
                      </FacebookMessengerShareButton>
                    </div>
                    <div
                      className="icon-div"
                      onClick={() => {
                        toggleModal();
                      }}
                       title="Share"
                    >
                      <Icon path={mdiShareVariant} className="mdi-social" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound
                text1="No Notes"
                text2="You can see notes here"
                img={require("../../images/nodata.png")}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ENoticeContent;
